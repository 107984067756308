import React from "react";
import myPhoto from "../content/my_photo_color.jpeg";

const Home = () => {
    return (
        <div className="home-content">
            <div className="row photo-content">
                <img src={myPhoto} alt="" className="my-photo" />
            </div>
            <div className="main-content">
                <h2><b>Hi, I'm Shashank!<span role="img" aria-label="Wave">👋</span></b></h2>
                <h4>I'm a Full-Stack Web Developer</h4>
            </div>
            <div className="sub-content">
                <h5><i className="bi bi-compass"></i> Based in Denver, CO</h5>
                <h5><i className="bi bi-laptop"></i> Software Developer at <a href="https://siliconmtn.com/" target="_blank" rel="noopener noreferrer">Silicon Mountain Technologies</a></h5>
                <a href="https://github.com/skuchibhotla" target="_blank" className="icon-link" rel="noopener noreferrer"><i class="bi bi-github"></i></a>
                <a href="https://www.linkedin.com/in/skuchibhotla18/" target="_blank" className="icon-link" rel="noopener noreferrer"><i class="bi bi-linkedin"></i></a>
            </div>
        </div>
    );
}

export default Home;

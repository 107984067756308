// import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import AboutMe from './components/AboutMe';
import Footer from './components/Footer';
import Experience from './components/Experience';

function App() {
  return (
    <div className="App">
      <Header />
      <div className='app-content'>
        <div className="background-overlay"></div>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about-me' element={<AboutMe />} />
          <Route path='/experience' element={<Experience />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Experience = () => {
    return (
        <div className="experience-content">
            <Container>
                <Row>
                    <Col sm={3}>
                        Nov 2020 - Present
                    </Col>
                    <Col sm={9}>
                        <b>Application Software Engineer * Silicon Mountain Technologies</b>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={9}>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                    </Col>
                </Row>
            </Container>

            <div className="experience-divider"></div>

            <Container>
                <Row>
                    <Col sm={3}>
                        Dec 2019 - Sep 2020
                    </Col>
                    <Col sm={9}>
                        <b>Software Engineer II * Gallup Inc.</b>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={9}>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                    </Col>
                </Row>
            </Container>
            
            <div className="experience-divider"></div>

            <Container>
                <Row>
                    <Col sm={3}>
                        Oct 2017 - Dec 2019
                    </Col>
                    <Col sm={9}>
                        <b>Software Engineer * EduMind Inc.</b>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={9}>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Experience;
import React from "react";
import { Container, Navbar } from "react-bootstrap";

const Footer = () => {
    return (
        <Navbar className="navbar-footer" expand="lg" sticky="bottom">
            <Container className="justify-content-center">
                <Navbar.Brand href="/">SK-Welt</Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default Footer;
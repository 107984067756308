import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
// import { Link } from "react-router-dom";

const Header = () => {
  return (
    <Navbar className="navbar-header" expand="lg" sticky="top">
      <Container>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/experience">Experience</Nav.Link>
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    // <nav className="navbar navbar-expand-lg bg-body-tertiary">
    //   <div className="container-fluid">
    //     {/* <Link className="navbar-brand" to={{
    //         pathname: "/"
    //     }}>SK</Link> */}
    //     <a className="navbar-brand" href="/">
    //       SK
    //     </a>
    //     <button
    //       className="navbar-toggler"
    //       type="button"
    //       data-bs-toggle="collapse"
    //       data-bs-target="#navbarNavAltMarkup"
    //       aria-controls="navbarNavAltMarkup"
    //       aria-expanded="false"
    //       aria-label="Toggle navigation"
    //     >
    //       <span className="navbar-toggler-icon"></span>
    //     </button>
    //     <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
    //       <div className="navbar-nav">
    //       <a className="nav-link active" aria-current="page" href="/home">
    //           Home
    //         </a>
    //         <a className="nav-link" aria-current="page" href="/about-me">
    //           About Me
    //         </a>
    //         {/* <Link className="nav-link" to={{
    //             pathname: "/about-me"
    //         }}>About Me</Link> */}
    //         {/* <a className="nav-link" href="#">
    //           Features
    //         </a>
    //         <a className="nav-link" href="#">
    //           Pricing
    //         </a>
    //         <a className="nav-link disabled" aria-disabled="true">
    //           Disabled
    //         </a> */}
    //       </div>
    //     </div>
    //   </div>
    // </nav>
  );
};

export default Header;
